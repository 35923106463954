import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification, NotificationType } from '../models/notification.model';
import { NotificationService } from '../notification.service';
import { ChapterService } from '../../chapter/chapter.service';
import { CourseService } from '../../course/course.service';
import { Router } from '@angular/router';
import { ReviewService } from '../../review/review.service';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { SkillService } from '../../skill/skill.service';
import { NbCardModule, NbAlertModule, NbListModule, NbButtonModule, NbIconModule, NbUserModule } from '@nebular/theme';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, DatePipe } from '@angular/common';
import { RelativeTimePipe } from '../../pipe/relative-time/relative-time.pipe';

@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
    standalone: true,
    imports: [NbCardModule, TranslateDirective, NgIf, NbAlertModule, NbListModule, NgFor, NgSwitch, NgSwitchCase, NbButtonModule, NbIconModule, NbUserModule, DatePipe, RelativeTimePipe, TranslatePipe]
})
export class NotificationListComponent {
  @Output() closePopover?: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  isInDashboard = false;

  notifications?: Notification[];

  placeholders: any = new Array(8);

  eNotificationType = NotificationType;

  includeReadNotifications = false;

  constructor(public notificationService: NotificationService,
              private chapterService: ChapterService,
              private courseService: CourseService,
              private skillService: SkillService,
              private reviewService: ReviewService,
              private router: Router,
              private matomoTracker: MatomoTracker) {
    this.loadNotifications();
  }

  clickOnNotification(notification: Notification): void {
    this.matomoTracker.trackEvent('notification', 'notificationListClick', notification.type);
    this.notificationService.markNotificationAsRead(notification.id).subscribe(() => {
      // We have to subscribe to make the call
    });
    switch (notification.type) {
      case NotificationType.TASK_RELEASED: {
        const taskReleasedNotification = this.notificationService.toTaskReleasedNotification(notification);
        // TODO: Support this for skill tasks
        this.chapterService.getChapterIdByTaskId(taskReleasedNotification.task.id, {}).subscribe(chapterId => {
          this.courseService.getCourseShortNameByChapterId(chapterId).subscribe(shortName => {
            this.router.navigate(['/course', shortName, 'chapter', chapterId, 'task', taskReleasedNotification.task.id]);
          });
        });
      } break;
      case NotificationType.CHAPTER_RELEASED: {
        const chapterReleasedNotification = this.notificationService.toChapterReleasedNotification(notification);
        this.courseService.getCourseShortNameByChapterId(chapterReleasedNotification.chapter.id).subscribe(shortName => {
          this.router.navigate(['/course', shortName], {fragment: chapterReleasedNotification.chapter.id});
        });
      } break;
      case NotificationType.TEAM_INVITATION_RECEIVED: {
        this.notificationService.toTeamInvitationReceivedNotification(notification);
        this.router.navigate(['/team', 'join']);
      } break;
      case NotificationType.ADDED_TO_COURSE:
      case NotificationType.MODIFIED_COURSE_ROLE: {
        const addedToCourseNotification = this.notificationService.toAddedToCourseNotification(notification);
        this.router.navigate(['/course', addedToCourseNotification.course.shortName]);
      } break;
      case NotificationType.REVIEW_APPROVED:
      case NotificationType.REVIEW_CHANGES_REQUESTED:
      case NotificationType.REVIEW_DECLINED:
      case NotificationType.REVIEW_ACKNOWLEDGED:
      {
        const reviewNotification = this.notificationService.toReviewNotification(notification);
        if (reviewNotification.review.receiver.courseMember) {
          this.reviewService.navigateToReviewView(reviewNotification.review, reviewNotification.review.submission.task.id, reviewNotification.review.receiver.courseMember.course as unknown as string);
        } else if (reviewNotification.review.reviewer.skillInstanceMember) {
          this.reviewService.navigateToSkillReviewView(reviewNotification.review, reviewNotification.review.submission.task.id, reviewNotification.review.receiver.skillInstanceMember.skillInstance as unknown as string, reviewNotification.review.receiver.skillInstanceMember.tenant as unknown as string);
        }
      } break;
      case NotificationType.REVIEW_RE_REQUESTED:
      {
        const reviewNotification = this.notificationService.toReviewNotification(notification);
        // TODO: Handle case where CourseMember re-requests review from skill instance member
        if (reviewNotification.review.receiver.skillInstanceMember) {
          const skillInstanceId = reviewNotification.review.receiver.skillInstanceMember.skillInstance as unknown as string;
          this.skillService.getSkillIdBySkillInstanceId(skillInstanceId).subscribe(skillId => {
            this.router.navigate(['/skill', skillId, 'instance', skillInstanceId, 'task', reviewNotification.review.submission.task?.id, 'review', 'create', reviewNotification.review.submission.id], {
              queryParams: {tenantId: reviewNotification.review.receiver.skillInstanceMember.tenant as unknown as string},
            });
          });
        } else if (reviewNotification.review.receiver.courseMember) {
          this.chapterService.getChapterIdByTaskId(reviewNotification.review.submission.task?.id, {courseId: reviewNotification.review.receiver.courseMember.course as unknown as string}).subscribe(chapterId => {
            this.courseService.getCourseShortNameByChapterId(chapterId).subscribe(shortName => {
              // eslint-disable-next-line max-len
              this.router.navigate(['/course', shortName, 'chapter', chapterId, 'task', reviewNotification.review.submission.task?.id, 'review', 'create', reviewNotification.review.submission.id]);
            });
          });
        }

      } break;
      case NotificationType.TASK_DEADLINE:
      case NotificationType.EVENT_UPCOMING:
      {
        const taskDeadlineNotification = this.notificationService.toTaskDeadlineNotification(notification);
        // TODO: Support this for skill events
        this.chapterService.getChapterIdByTaskId(taskDeadlineNotification.task.id, {}).subscribe(chapterId => {
          this.courseService.getCourseShortNameByChapterId(chapterId).subscribe(shortName => {
            this.router.navigate(['/course', shortName, 'chapter', chapterId, 'task', taskDeadlineNotification.task.id]);
          });
        });
      } break;
      case NotificationType.APPLICATION_REVIEW_ASSIGNED: {
        const applicationReviewNotification = this.notificationService.toApplicationReviewNotification(notification);
        this.router.navigate(['/application', 'review', applicationReviewNotification.applicationReview.id]);
        break;
      }
      case NotificationType.PERSON_ASSIGNED:
        const personAssignedNotification = this.notificationService.toPersonAssignedNotification(notification);
        this.router.navigate(['/person', personAssignedNotification.person.id, 'claim']);
        break;
      case NotificationType.REGISTRATION_STATE_UPDATE: {
        const registrationStateUpdateNotification = this.notificationService.toRegistrationUpdatedStateNotification(notification);
        const registrationProcess = registrationStateUpdateNotification.registration.registrationProcess;
        const skill = !!registrationProcess.skillInstance;

        if (skill) {
          this.skillService.getCourseAndChapterForOwnSkillInstanceMember(notification.receiverSkillInstanceMember as unknown as string).subscribe(({chapter}) => {
            const queryParams: any = {skill};
            if (chapter) {
              queryParams.chapterId = chapter.id;
            }
            this.router.navigate(['/registration', registrationProcess.id, 'register'], {queryParams});
          });
        } else {
          this.router.navigate(['/registration', registrationProcess.id, 'register']);
        }
        break;
      }
    }

    this.notificationService.updateHasUnreadNotifications();
    this.closePopover.emit();
  }

  markAllAsRead(): void {
    this.notificationService.markAllNotificationsAsRead().subscribe(() => {
      this.notifications.forEach(n => n.read = true);
      this.notificationService.updateHasUnreadNotifications();
      this.closePopover.emit();
    });
  }

  loadNotifications(): void {
    this.notificationService.getNotifications(this.includeReadNotifications).subscribe(res => {
      this.notificationService.updateHasUnreadNotifications();
      this.notifications = res;
      this.placeholders = [];
    });
  }

}
