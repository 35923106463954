import { Component, ViewChild } from '@angular/core';
import { NotificationService } from '../notification.service';
import { NbPopoverDirective, NbButtonModule, NbPopoverModule, NbIconModule } from '@nebular/theme';
import { Observable } from 'rxjs';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgIf, AsyncPipe } from '@angular/common';
import { NotificationListComponent } from '../notification-list/notification-list.component';

@Component({
    selector: 'app-notification-bell',
    templateUrl: './notification-bell.component.html',
    styleUrls: ['./notification-bell.component.scss'],
    standalone: true,
    imports: [NbButtonModule, NgxMatomoTrackerModule, NbPopoverModule, NbIconModule, NgIf, NotificationListComponent, AsyncPipe]
})
export class NotificationBellComponent {

  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  unreadNotifications$: Observable<boolean>;

  constructor(private notificationService: NotificationService) {
    this.unreadNotifications$ = this.notificationService.hasUnreadNotifications;
  }

  closePopover() {
    this.popover.hide();
  }

}
