import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { UserGuard } from './core/guards/user.guard';
import { TeamGuard } from './core/guards/team.guard';
import { FeatureGuard } from './core/guards/feature.guard';
import { InstructorGuard } from './core/guards/instructor.guard';
import { SuperAdminGuard } from './core/guards/super-admin.guard';
import { ApplicationAuthGuard } from './core/guards/application-auth.guard';
import { TenantLinkType } from './tenant/tenant.model';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 0],
  useHash: false,
  onSameUrlNavigation: 'reload',
};

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./landing/landing/landing.component').then((m) => m.LandingComponent),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.routes').then((m) => m.AUTH_ROUTES),
  },
  {
    path: 'user/profile',
    canActivate: [AuthGuard, UserGuard],
    loadComponent: () => import('./user/profile/profile.component').then((m) => m.ProfileComponent),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./dashboard/dashboard/dashboard.component').then((m) => m.DashboardComponent),
  },
  {
    path: 'course',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./course/course.routes').then((m) => m.COURSE_ROUTES),
  },
  {
    path: 'chapter',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./chapter/chapter.routes').then((m) => m.CHAPTER_ROUTES),
  },
  {
    path: 'administration',
    canActivate: [AuthGuard, AdminGuard],
    loadComponent: () =>
      import('./administration/administration/administration.component').then(
        (m) => m.AdministrationComponent
      ),
  },
  {
    path: 'team',
    loadChildren: () => import('./team/team.routes').then((m) => m.TEAM_ROUTES),
  },
  {
    path: 'tenant',
    canActivate: [AuthGuard, SuperAdminGuard],
    loadChildren: () => import('./tenant/tenant.routes').then((m) => m.TENANT_ROUTES)
  },
  {
    path: 'application',
    canActivate: [FeatureGuard],
    loadChildren: () => import('./application/application.routes').then((m) => m.APPLICATION_ROUTES),
    data: {
      feature: 'application',
    },
  },
  {
    path: 'program',
    canActivate: [AuthGuard, InstructorGuard],
    loadChildren: () => import('./program/program.routes').then((m) => m.PROGRAM_ROUTES),
  },
   {
    path: 'file',
    canActivate: [AuthGuard],
    loadChildren: () => import('./file/file.routes').then((m) => m.FILE_ROUTES),
  },
  {
    path: 'skill',
    canActivate: [FeatureGuard],
    loadChildren: () => import('./skill/skill.routes').then((m) => m.SKILL_ROUTES),
    data: {
      feature: 'skills',
    },
  },
  {
    path: 'person',
    loadChildren: () => import('./person/person.routes').then((m) => m.PERSON_ROUTES),
  },
  {
    path: 'community',
    loadComponent: () => import('./community/community/community.component').then((m) => m.CommunityComponent),
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.routes').then((m) => m.REGISTRATION_ROUTES),
  },
  {
    path: 'email',
    loadChildren: () => import('./email/email.routes').then((m) => m.EMAIL_ROUTES),
  },
  {
    path: 'migration',
    loadComponent: () => import('./tenant/tenant-migration-confirm/tenant-migration-confirm.component').then(m => m.TenantMigrationConfirmComponent),
  },
  {
    path: 'privacy',
    loadComponent: () => import('./layout/custom-tenant-page/custom-tenant-page.component').then(m => m.CustomTenantPageComponent),
    data: {
      linkType: TenantLinkType.PRIVACY,
    }
  },
  {
    path: 'terms',
    loadComponent: () => import('./layout/custom-tenant-page/custom-tenant-page.component').then(m => m.CustomTenantPageComponent),
    data: {
      linkType: TenantLinkType.TERMS,
    }
  },
  {
    path: 'legal',
    loadComponent: () => import('./layout/custom-tenant-page/custom-tenant-page.component').then(m => m.CustomTenantPageComponent),
    data: {
      linkType: TenantLinkType.LEGAL,
    }
  },
  {
    path: '**',
    loadComponent: () => import('./layout/not-found/not-found.component').then(m => m.NotFoundComponent),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [AuthGuard, AdminGuard, UserGuard, TeamGuard, FeatureGuard, InstructorGuard, SuperAdminGuard, ApplicationAuthGuard],
})
export class AppRouting {
}
