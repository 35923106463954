import { Component, Input } from '@angular/core';
import { NbDialogRef, NbCardModule, NbCheckboxModule, NbButtonModule } from '@nebular/theme';
import { Team } from '../team.model';
import { TeamService } from '../team.service';
import { Tenant } from '../../tenant/tenant.model';
import { ManagementService } from '../../core/services/management.service';
import { TranslateDirective } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-team-activate-dialog',
    templateUrl: './team-activate-dialog.component.html',
    styleUrls: ['./team-activate-dialog.component.scss'],
    standalone: true,
    imports: [NbCardModule, TranslateDirective, NbCheckboxModule, FormsModule, NbButtonModule]
})
export class TeamActivateDialogComponent {

  @Input()
  team: Team;

  @Input()
  activated: () => void;

  tenant: Tenant;
  termsAccepted = false;
  privacyAccepted = false;

  activatedByUser = false;

  termsUrl?: string;
  privacyUrl?: string;

  constructor(
    protected ref: NbDialogRef<TeamActivateDialogComponent>,
    private teamService: TeamService,
    private tenantService: ManagementService,
  ) {
    this.tenant = this.tenantService.tenant;

    this.termsUrl = this.tenant?.tenantLinks?.termsUrl || '/terms';
    this.privacyUrl = this.tenant?.tenantLinks?.privacyUrl || '/privacy';
  }

  activateTeam(): void {
    this.teamService.activateTeamForTenant(this.team.id).subscribe({
      next: () => {
        this.activatedByUser = true;
        this.activated();
        this.ref.close();
      },
      error: () => {
        this.activatedByUser = true;
        this.activated();
        this.ref.close();
      }
    });
  }
}
