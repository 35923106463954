import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-page-ribbon',
    templateUrl: './page-ribbon.component.html',
    styleUrls: ['./page-ribbon.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class PageRibbonComponent implements OnInit {
  ribbonEnv?: string;

  ngOnInit(): void {
    const ribbonText = environment.ribbon;

    if (ribbonText) {
      this.ribbonEnv = ribbonText;
    }
  }
}
