import { Component, Input } from '@angular/core';
import { NbDialogRef, NbCardModule, NbButtonModule } from '@nebular/theme';
import { TranslateDirective } from '@ngx-translate/core';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    standalone: true,
    imports: [NbCardModule, TranslateDirective, NbButtonModule]
})
export class ConfirmDialogComponent {
  @Input() subjectKey: string;
  @Input() hintKey: string;
  @Input() translateParams: any;

  @Input() onCancel: () => void;
  @Input() onConfirm?: () => void;

  constructor(protected ref: NbDialogRef<ConfirmDialogComponent>,) { }

  confirm(): void {
    this.onConfirm();
    this.ref.close();
  }

  dismiss() {
    if (this.onCancel) {
      this.onCancel();
    }
    this.ref.close();
  }

}
