import { Component, OnInit } from '@angular/core';
import { RoleService } from '../role.service';
import { Observable } from 'rxjs';
import { NgIf, AsyncPipe } from '@angular/common';
import { NbAlertModule, NbButtonModule, NbIconModule } from '@nebular/theme';
import { TranslateDirective } from '@ngx-translate/core';

@Component({
    selector: 'app-role-updated-banner',
    templateUrl: './role-updated-banner.component.html',
    styleUrls: ['./role-updated-banner.component.scss'],
    standalone: true,
    imports: [NgIf, NbAlertModule, TranslateDirective, NbButtonModule, NbIconModule, AsyncPipe]
})
export class RoleUpdatedBannerComponent {

  showPendingChangeBanner$: Observable<boolean>;

  constructor(private roleService: RoleService) {
    this.showPendingChangeBanner$ = this.roleService.pendingPermissionUpdate;
  }

  reload(): void {
    location.reload();
  }
}
