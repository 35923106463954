import { Component } from '@angular/core';
import { NbCardModule, NbButtonModule } from '@nebular/theme';
import { TranslateDirective } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: true,
    imports: [
        NbCardModule,
        TranslateDirective,
        NbButtonModule,
        RouterLink,
    ],
})
export class NotFoundComponent {
}
