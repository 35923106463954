// TODO: How to handle this module?

import { ModuleWithProviders, NgModule } from '@angular/core';

import { NbDateService } from '@nebular/theme';
import { CustomDateFnsService } from './custom-date-fns.service';

const dateFnsServiceProvider = { provide: NbDateService, useClass: CustomDateFnsService };

@NgModule({
  providers: [ dateFnsServiceProvider ],
})
export class CustomDateFnsModule {
  static forRoot(): ModuleWithProviders<CustomDateFnsModule> {
    return {
      ngModule: CustomDateFnsModule,
      providers: [
        dateFnsServiceProvider,
      ],
    };
  }

  static forChild(): ModuleWithProviders<CustomDateFnsModule> {
    return {
      ngModule: CustomDateFnsModule,
      providers: [
        dateFnsServiceProvider,
      ],
    };
  }
}
