import { NgModule } from '@angular/core';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbBadgeModule,
  NbButtonGroupModule,
  NbButtonModule,
  NbCalendarModule,
  NbCardModule,
  NbChatModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbPopoverModule,
  NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTableModule,
  NbTabsetModule,
  NbTagModule,
  NbThemeModule,
  NbTimepickerModule,
  NbToggleModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbUserModule,
  NbWindowModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import { MarkAsteriskDirective } from './core/directives/mark-asterisk/mark-asterisk.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextareaAutoresizeDirective } from './core/directives/textarea-autoresize/textarea-autoresize.directive';
import { FeatureFlagDirective } from './core/directives/feature-flag/feature-flag.directive';
import { UrlValidDirective } from './core/directives/url-valid/url-valid.directive';
import { UrlParamValidDirective } from './core/directives/url-param-valid/url-valid.directive';
import { EmailValidDirective } from './core/directives/email-valid/email-valid.directive';
import { HoverClassDirective } from './core/directives/hover-class/hover-class.directive';
import { FirebaseLinkShortenerValidDirective } from './core/directives/firebase-link-shortener-valid/firebase-link-shortener-valid.directive';
import { IsMiroSetDirective } from './core/directives/is-miro-set/is-miro-set.directive';
import { CustomDateFnsModule } from './custom-date-fns/custom-date-fns.module';

@NgModule({
    imports: [
        NbDatepickerModule.forRoot(),
        NbTimepickerModule.forRoot(),
        // Add custom date format for datepicker
        CustomDateFnsModule.forRoot(),
        NbSecurityModule.forRoot({
            accessControl: {
                guest: {
                    view: ['test'],
                    create: [],
                    remove: [],
                },
                user: {
                    parent: 'guest',
                    create: ['team'],
                    edit: ['team']
                },
                coach: {
                    parent: 'user',
                    read: ['submission'],
                },
                instructor: {
                    parent: 'coach',
                    create: ['course', 'chapter', 'task', 'teamadd'],
                    edit: ['course', 'chapter', 'task', 'team', 'community'],
                    remove: [],
                    view: ['team-overview', 'program-dashboard', 'skill'],
                },
                admin: {
                    parent: 'instructor',
                    create: 'skill',
                    view: 'admin-dashboard',
                    edit: '*',
                    read: 'user-credentials',
                    remove: ['user', 'team'],
                },
                superadmin: {
                    remove: '*',
                    parent: 'admin',
                    view: '*',
                },
            },
        }),
        NbDialogModule.forRoot(),
        NbWindowModule.forRoot(),
        MarkAsteriskDirective, TextareaAutoresizeDirective, FeatureFlagDirective, UrlValidDirective, UrlParamValidDirective, EmailValidDirective, FirebaseLinkShortenerValidDirective, HoverClassDirective, FirebaseLinkShortenerValidDirective, FirebaseLinkShortenerValidDirective, IsMiroSetDirective,
    ],
    exports: [
        NbAccordionModule,
        NbActionsModule,
        NbAlertModule,
        NbAutocompleteModule,
        NbBadgeModule,
        NbButtonGroupModule,
        NbButtonModule,
        NbCalendarModule,
        NbCardModule,
        NbChatModule,
        NbCheckboxModule,
        NbContextMenuModule,
        NbDatepickerModule,
        NbDialogModule,
        NbEvaIconsModule,
        NbFormFieldModule,
        NbIconModule,
        NbInputModule,
        NbLayoutModule,
        NbListModule,
        NbRadioModule,
        NbSecurityModule,
        NbSelectModule,
        NbSpinnerModule,
        NbStepperModule,
        NbPopoverModule,
        NbTableModule,
        NbTabsetModule,
        NbTagModule,
        NbThemeModule,
        NbTimepickerModule,
        NbToggleModule,
        NbTooltipModule,
        NbTreeGridModule,
        NbUserModule,
        MarkAsteriskDirective,
        TextareaAutoresizeDirective,
        DragDropModule,
        NbWindowModule,
        FeatureFlagDirective,
        UrlValidDirective,
        UrlParamValidDirective,
        EmailValidDirective,
        FirebaseLinkShortenerValidDirective,
        HoverClassDirective,
        FirebaseLinkShortenerValidDirective,
        FirebaseLinkShortenerValidDirective,
        IsMiroSetDirective,
    ],
})
export class NebularModule {
}
