import { Component } from '@angular/core';
import { BuildDetailsService } from '../../core/services/build-details.service';
import { Observable } from 'rxjs';
import { NgIf, AsyncPipe } from '@angular/common';
import { NbAlertModule, NbButtonModule, NbIconModule } from '@nebular/theme';
import { TranslateDirective } from '@ngx-translate/core';

@Component({
    selector: 'app-build-updated-banner',
    templateUrl: './build-updated-banner.component.html',
    styleUrls: ['./build-updated-banner.component.scss'],
    standalone: true,
    imports: [NgIf, NbAlertModule, TranslateDirective, NbButtonModule, NbIconModule, AsyncPipe]
})
export class BuildUpdatedBannerComponent {

  buildIsUpToDate$: Observable<boolean>;

  constructor(private buildDetailsService: BuildDetailsService) {
    this.buildIsUpToDate$ = this.buildDetailsService.buildIsUpToDate$;
  }

  reload(): void {
    location.reload();
  }
}
