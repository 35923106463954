import { Component, Input, OnInit } from '@angular/core';
import { CourseMember } from '../../course-member/course-member.model';
import { TeamService } from '../../team/team.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NbDialogRef, NbCardModule, NbListModule, NbUserModule } from '@nebular/theme';
import { UserService } from '../../user/user.service';
import { NgIf, NgFor } from '@angular/common';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-alternative-course-member-select-dialog',
    templateUrl: './alternative-course-member-select-dialog.component.html',
    styleUrls: ['./alternative-course-member-select-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, NbCardModule, TranslateDirective, NbListModule, NgFor, NbUserModule, TranslatePipe]
})
export class AlternativeCourseMemberSelectDialogComponent implements OnInit {

  @Input() courseMembers: CourseMember[];
  @Input() targetPath: string;

  currentContext: string;
  loaded = false;

  constructor(
    protected ref: NbDialogRef<AlternativeCourseMemberSelectDialogComponent>,
    private teamService: TeamService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userService.getUserInfo().subscribe(user => {
      if (this.teamService.isTeamMode()) {
        this.currentContext = user.teams.find(t => t.id === this.teamService.getTeamIdentifier())?.name || 'Unknown team';
      } else {
        this.currentContext = user.fullName;
      }
      this.loaded = true;
    });
  }

  selectTeam(teamId: string): void {
    this.teamService.changeTeamIdentifier(teamId);
    this.router.navigateByUrl(this.targetPath);
    this.ref.close();
  }

}
