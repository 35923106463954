import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'relativeTime',
    standalone: true
})
export class RelativeTimePipe implements PipeTransform {

  transform(value: Date, ..._args: unknown[]): string {
    // TODO: Make i18n conform
    if (!(value instanceof Date)) {
      value = new Date(value);
    }

    const seconds: number = Math.floor(((new Date()).getTime() - value.getTime()) / 1000);
    let interval: number = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + 'y ago';
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + 'm ago';
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + 'd ago';
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + 'h ago';
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + 'min ago';
    }
    return 'few sec ago';
  }
}
