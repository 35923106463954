import { Component } from '@angular/core';
import { MaintenanceService } from './maintenance.service';
import { NgIf } from '@angular/common';
import { NbAlertModule } from '@nebular/theme';

@Component({
    selector: 'app-maintenance-banner',
    templateUrl: './maintenance-banner.component.html',
    styleUrls: ['./maintenance-banner.component.scss'],
    standalone: true,
    imports: [NgIf, NbAlertModule]
})
export class MaintenanceBannerComponent {

  maintenanceMessage?: string;

  constructor(private maintenanceService: MaintenanceService) {
    this.maintenanceService.getMaintenanceMessage().subscribe(maintenanceMessage => {
      this.maintenanceMessage = maintenanceMessage;
    });
  }
}
